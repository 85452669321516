import React, { useState } from 'react'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select';

const PreInterview = () => {

    const navigate = useNavigate()

    const [jobType, setJobType] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [techSkills, setTechSkills] = useState([]);
    const [behavioralSkills, setBehavioralSkills] = useState([]);
    const [yearsOfExperience, setYearsOfExperience] = useState('');

    const tips = [
        "try to keep eye contact",
        "smile from time to time",
        "keep your body still",
        "keep your voice lively and well modulated"
    ];

    const techOptions = [
        { value: 'frontend', label: 'Frontend' },
        { value: 'backend', label: 'Backend' },
        { value: 'fullstack', label: 'FullStack' },
        { value: 'devops', label: 'DevOps' },
        { value: 'databases', label: 'Databases' }
    ];

    const behavioralOptions = [
        { value: 'communication', label: 'Communication' },
        { value: 'leadership', label: 'Leadership' },
        { value: 'teamwork', label: 'Teamwork' },
        { value: 'adaptability', label: 'Adaptability' },
        { value: 'problem-solving', label: 'Problem Solving' }
    ];

    const handleJobTitleChange = (event) => {
        setJobTitle(event.target.value);
    };

    const handleExperienceChange = (event) => {
        setYearsOfExperience(event.target.value);
    };

    const handleTechSkillsChange = (selectedOptions) => {
        setTechSkills(selectedOptions);
    };

    const handleBehavioralSkillsChange = (selectedOptions) => {
        setBehavioralSkills(selectedOptions);
    };

    const handleStartInterview = () => {
        const interviewData = {
            jobTitle,
            jobType,
            yearsOfExperience,
            techSkills,
            behavioralSkills
        };

        navigate('/interview', { state: interviewData });
    };

    return (
        <div >
            <Header></Header>

            <h3 className='px-5 my-4 font-500 text-lg ml-2'>Enter the information to start 30 minutes interview</h3>

            <div className='border border-slate-300 shadow-md mx-auto w-[96%] rounded-md p-7'>
                <div className='w-[50%] mb-5'>
                    <select
                        className='w-full p-2 border border-gray-300 rounded-md'
                        value={jobTitle}
                        onChange={handleJobTitleChange}
                    >
                        <option value='' disabled>Select your job title</option>
                        <option value='frontend'>Frontend</option>
                        <option value='backend'>Backend</option>
                        <option value='fullstack'>FullStack</option>
                    </select>
                </div>

                <div className='mb-5'>
                    <div className='flex space-x-6'>
                        <label className='flex items-center'>
                            <input
                                type='radio'
                                value='technical'
                                checked={jobType === 'technical'}
                                onChange={() => setJobType('technical')}
                                className='form-radio'
                            />
                            <span className='ml-2'>Technical</span>
                        </label>
                        <label className='flex items-center'>
                            <input
                                type='radio'
                                value='behavioral'
                                checked={jobType === 'behavioral'}
                                onChange={() => setJobType('behavioral')}
                                className='form-radio'
                            />
                            <span className='ml-2'>Behavioral</span>
                        </label>
                        <label className='flex items-center'>
                            <input
                                type='radio'
                                value='mix'
                                checked={jobType === 'mix'}
                                onChange={() => setJobType('mix')}
                                className='form-radio'
                            />
                            <span className='ml-2'>Mix</span>
                        </label>
                    </div>
                </div>

                <div className='w-[50%] mb-5'>
                    <input
                        type='number'
                        placeholder='Enter years of Experience'
                        className='w-full p-2 border border-gray-300 rounded-md'
                        value={yearsOfExperience}
                        onChange={handleExperienceChange}
                    />
                </div>

                <h2 className='mb-5 text-lg font-semibold'>Select skills to be evaluated (optional):</h2>

                {jobType !== "behavioral" && <div className='w-[50%] mb-5'>
                    <div className='flex items-center'>
                        <div className='w-[40%] mr-3'>
                            <h2 className='text-lg whitespace-nowrap font-medium'>Tech Skills (Max 5)</h2>
                        </div>
                        <Select
                            isMulti
                            name="techSkills"
                            options={techOptions}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder='Select your tech skills'
                            value={techSkills}
                            closeMenuOnSelect={false}
                            onChange={handleTechSkillsChange}
                        />
                    </div>
                </div>}

                {jobType !== "technical" && <div className='w-[50%] mb-5'>
                    <div className='flex items-center'>
                        <div className='w-[40%] mr-3'>
                            <h2 className='text-lg whitespace-nowrap font-medium'>Behavioral Skills (Max 5)</h2>
                        </div>
                        <Select
                            isMulti
                            name="behavioralSkills"
                            options={behavioralOptions}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder='Select your behavioral skills'
                            value={behavioralSkills}
                            closeMenuOnSelect={false}
                            onChange={handleBehavioralSkillsChange}
                        />
                    </div>
                </div>}

                <div className='text-center mt-8'>
                    <button className='mt-3 bg-teal-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-teal-600 transition duration-300'
                        onClick={handleStartInterview}>
                        Start Interview
                    </button>
                </div>
            </div>

            <div className='ml-2 px-5 my-4 '>
                <h3 className='font-bold text-xl text-blue-700'>General Tips:</h3>

                <ul className='list-disc list-inside'>
                    {tips.map((tip, index) => (
                        <li key={index}>{tip}</li>
                    ))}
                </ul>
            </div>
        </div >
    )
}

export default PreInterview
