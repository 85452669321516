import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const InterviewCompleted = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { questions, answers } = location.state || {};

    const handleViewFeedback = () => {
        navigate('/feedback-report', { state: { questions, answers } });
    };

    const handleReturnHome = () => {
        navigate('/')
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-12 rounded-lg shadow-md max-w-lg text-center">
                <h1 className="text-4xl font-semibold text-teal-600 mb-4">Interview Completed!</h1>
                <p className="text-gray-700 mb-6">
                    Congratulations! You've successfully completed the interview.
                    Take a moment to review your answers or return to the homepage.
                </p>

                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleViewFeedback}
                        className="bg-teal-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-teal-600 transition duration-300"
                    >
                        View Feedback
                    </button>
                    <button
                        onClick={handleReturnHome}
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-600 transition duration-300"
                    >
                        Return Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InterviewCompleted;
