import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const FeedbackReport = () => {
    const [feedback, setFeeback] = useState(null)

    const location = useLocation();
    const { questions, answers } = location.state || {};

    useEffect(() => {
        if (questions && answers && !feedback)
            generateFeedback();
    }, [questions, answers])

    // const questions = [
    //     "Can you explain the concept of object-oriented programming?",
    //     "What is the time complexity of binary search?",
    //     "How do you manage state in React applications?",
    //     "Can you describe a time when you solved a problem as part of a team?",
    //     "How would you optimize a slow-performing SQL query?"
    // ];

    // const answers = [
    //     "Object-oriented programming is a paradigm where concepts are represented as objects with data and methods.",
    //     "The time complexity of binary search is O(log n) since it divides the search space in half with each step.",
    //     "State in React can be managed using useState for local state, useContext for global state, or external libraries like Redux for more complex state management.",
    //     "In my previous project, I collaborated with a team to refactor the codebase, dividing the tasks based on expertise and efficiently resolving the technical debt.",
    //     "I would optimize the query by analyzing execution plans, adding indexes where necessary, and refactoring joins or subqueries into more efficient structures."
    // ];

    const generateFeedback = async () => {
        try {
            const response = await fetch('https://aceinterview.co.in/api/generate-feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    questions: questions,
                    answers: answers,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log(data, 'dddddddddddddd')
            const parsedData = parseFeedback(data['feedback'])
            // console.log(parsedData, 'ppppppp')
            setFeeback(parsedData)
        } catch (error) {
            console.error('Error generating feedback:', error);
        }
    };

    const parseFeedback = (rawArray) => {
        try {
            let rawData = rawArray.join(' ').replace(/```json|```|\n/g, '').trim();
            rawData = rawData.replace(/\s+/g, ' ');
            rawData = rawData.replace(/,\s*}/g, '}');
            rawData = rawData.replace(/,\s*]/g, ']');
            return JSON.parse(rawData);
        } catch (error) {
            console.error('Error parsing feedback:', error);
            return null;
        }
    };


    return (
        <div className="w-[75%] mx-auto p-6 bg-white shadow-lg rounded-lg">
            <div className="border-b border-gray-200 pb-4 mb-4">
                <h1 className="text-2xl font-semibold text-blue-600">Ace Interview</h1>
                <button onClick={() => generateFeedback()} >GENERATE</button>
            </div>
            <div className="flex justify-between items-center border-b border-gray-200 pb-4 mb-4">
                <h2 className="text-xl font-semibold">Feedback Report</h2>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <h3 className="text-lg font-semibold mb-2">Candidate Information</h3>
                <div className="flex flex-row mb-1">
                    <p className="w-[40%]"><span className="font-bold">Name:</span> Vishal Gupta</p>
                    <p ><span className="font-bold">Job Title:</span> Product Manager</p>
                </div>
                <div className="flex flex-row mb-1">
                    <p className="w-[40%]"><span className="font-bold">Interview Type:</span> Technical Interview</p>
                    <p><span className="font-bold">Date:</span> July 8, 2024</p>
                </div>
            </div>
            {feedback && <div>
                <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h3 className="text-lg font-semibold mb-2">Overall Performance Summary</h3>
                    <p><span className="font-bold">Score:</span> {feedback.overallPerformance.score}/100</p>
                    <span className="font-bold">Strengths:</span>
                    <ul>
                        {feedback.overallPerformance.strengths.map((strength, index) => (
                            <li key={index}>{strength}</li>
                        ))}
                    </ul>
                    <span className="font-bold">Areas for Improvement:</span>  <ul>
                        {feedback.overallPerformance.areasOfImprovement.map((area, index) => (
                            <li key={index}>{area}</li>
                        ))}
                    </ul>
                </div>
                <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h3 className="text-lg font-semibold">Detailed Feedback</h3>
                    <div className="mb-4">
                        <h4 className="text-md font-semibold text-blue-600">Technical Skills</h4>
                        {feedback.skillsFeedback.map((skillFeedback, index) => (
                            <div key={index} className="skill-feedback">
                                <h4 className="text-md font-semibold">{skillFeedback.skill}</h4>
                                <div className="px-4">
                                    <p><strong>Evaluation:</strong> {skillFeedback.evaluation}</p>
                                    <p><strong>Recommendations:</strong> {skillFeedback.recommendations}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h4 className="text-md font-semibold text-blue-600">Behavioral Skills</h4>
                        {feedback.behavioralSkills.map((skillFeedback, index) => (
                            <div key={index} className="skill-feedback">
                                <div className="px-4">
                                    <p>
                                        <span className="font-bold">{skillFeedback.skill}: </span>
                                        {skillFeedback.evaluation}</p>
                                </div>
                            </div>
                        ))}</div>
                </div>
                <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h3 className="text-lg font-semibold">Next Steps</h3>
                    <p>Follow-up: Schedule another mock interview in three weeks to assess progress.</p>
                </div>
                <div className="text-center mt-6">
                    <p className="font-bold">Conclusion:</p>
                    <p>{feedback.conclusion}</p>
                    <p>Great effort! Keep practicing, and you'll see significant improvements. Feel free to reach out if you have any questions or need further guidance.</p>
                    <p className="text-sm mt-4">Contact Information: Email: support@mockinterviewai.com</p>
                </div>
            </div>
            }
        </div>
    );
};

export default FeedbackReport;
