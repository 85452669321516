import React, { useState } from 'react';
import aiImage from './images/ai.JPG';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log(formData);
    };


    return (
        <>
            <header className="bg-slate-100 text-black py-4 px-2 shadow-md ml-2">
                <div className="container mx-auto flex justify-between items-center px-4">
                    <h1 className="text-2xl font-bold">AceInterview</h1>
                    <nav>
                        <ul className="flex space-x-5">
                            <li>
                                <a href="/" className="bg-black text-white font-bold py-2 px-4 border border-gray-400 rounded shadow">Signin</a>
                            </li>
                            <li>
                                <a href="/" className="bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">Register</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                    <div style={styles.imageContainer}>
                        <img src={aiImage} alt="Login" style={styles.image} />
                    </div>
                    <div style={styles.formContainer}>
                        <h2>Log in to your account</h2>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.inputGroup}>
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.inputGroup}>
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    style={styles.input}
                                />
                            </div>
                            <button type="submit" style={styles.button}>Log In</button>
                        </form>
                        <p className="signup-text">Don't have an account? <a href="./Signup">Sign up instead</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#F5F5F5',
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: '1000px',
        padding: '40px',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    },
    imageContainer: {
        flex: 1,
        marginRight: '20px',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    inputGroup: {
        marginBottom: '20px',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
    },
    checkboxGroup: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#28a745',
        color: '#ffffff',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
    Header: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-5px',
    },
};

export default Login;
