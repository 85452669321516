import { Route, Routes } from 'react-router-dom';
import './App.css';
import PreInterview from './components/PreInterview';
import Interview from './components/Interview';
import InterviewCompleted from './components/InterviewCompleted';
import Signup from './components/Signup';
import Login from './components/Login';
import FeedbackReport from './components/FeedbackReport';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Signup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/interview-completed' element={<InterviewCompleted />} />
        <Route path='/pre-interview' element={<PreInterview />} />
        <Route path='/interview' element={<Interview />} />
        <Route path='/feedback-report' element={<FeedbackReport />} />
      </Routes>
    </div>
  );
}

export default App;
