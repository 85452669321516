import React, { useEffect, useState } from 'react';
import Header from './Header';
import Webcam from "react-webcam";
import useSpeechToText from 'react-hook-speech-to-text';
import { ReactMic } from "react-mic";
import { useLocation, useNavigate } from 'react-router-dom';
import screenfull from 'screenfull';

const Interview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { jobTitle, jobType, yearsOfExperience, techSkills, behavioralSkills } = location.state || {};

  const [hasPermission, setHasPermission] = useState(false);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [record, setRecord] = useState(false);
  const [myAudioSrc, setMyAudioSrc] = useState(null);

  const [curQuestion, setCurQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [disableButton, setDisableButton] = useState(false)


  useEffect(() => {
    try {
      const videoPermission = navigator.permissions.query({ name: 'camera' });
      const audioPermission = navigator.permissions.query({ name: 'microphone' });

      if (videoPermission.state === 'granted' && audioPermission.state === 'granted') {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    } catch (error) {
      console.error('Error checking media device permissions.', error);
      setHasPermission(false);
    }
    handleRequestAccess()
  }, [location])

  useEffect(() => {
    if (interviewStarted && curQuestion === questions.length && answers.length === questions.length) {
      navigate('/interview-completed', { state: { questions, answers } });
    }
  }, [answers, curQuestion]);

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  const handleNextClick = () => {
    stopSpeechToText();
    if (results.length > 0) {
      setAnswers(prevAnswers => [...prevAnswers, results.map(result => result.transcript).join(' ')]);
      setResults([]);
    }
    else {
      setAnswers(prevAnswers => [...prevAnswers, [" "]]);
      setResults([]);
    }
    setDisableButton(true);
    if (curQuestion !== questions.length - 1)
      readOut(questions[curQuestion + 1], () => {
        startSpeechToText();
        setDisableButton(false);
      });
    setCurQuestion(prevQuestion => prevQuestion + 1);
  };

  const onData = (recordedBlob) => {
    console.log("chunk of real-time data is: ", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    console.log("recordedBlob is: ", recordedBlob);
    var url = URL.createObjectURL(recordedBlob.blob);
    setMyAudioSrc(url);
  };

  const videoConstraints = {
    width: 860,
    height: 440,
    facingMode: "user"
  };

  const handleRequestAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setHasPermission(true);
    } catch (error) {
      setHasPermission(false);
      console.error('Error accessing media devices.', error);
    }
  };

  const handleUserMedia = () => {
    setHasPermission(true);
  };

  const handleUserMediaError = () => {
    setHasPermission(false);
  };

  const readOut = (text, onComplete) => {
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance(text);
      if (onComplete) {
        speech.onend = onComplete;
      }

      window.speechSynthesis.speak(speech)
    }
    else {
      alert('sorry u r browser doesnot support text to speech')
    }
  }

  const {
    error,
    interimResult,
    isRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
    crossBrowser: true,
  });

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

  const styles = {
    webcamContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    speakingContainer: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: '10px',
    },
    imageContainer: {
      position: 'relative',
    },
    speakingImage: {
      width: '110px', 
      height: 'auto',
    },
    waveContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      overflow: 'hidden',
    },
    wave: {
      position: 'absolute',
      bottom: 0,
      width: '200%',
      height: '200%',
      background: 'rgba(0, 123, 255, 0.3)',
      borderRadius: '50%',
      animation: 'wave 1.5s infinite',
    },
    wave1: {
      animationDelay: '0s',
    },
    wave2: {
      animationDelay: '0.5s',
    },
    wave3: {
      animationDelay: '1s',
    },
    speakingText: {
      fontSize: '18px',
    },
  };

  // Define keyframes for wave animation
  const keyframes = `
  @keyframes wave {
    0% {
      transform: scale(0);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 0.3;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  `;

  // Add keyframes to document head
  const addKeyframes = () => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);
  };

  addKeyframes();

  const handleBeginInterview = () => {
    setResults([]);
    screenfull.request()
    if (!hasPermission) handleRequestAccess()
    else
      generateQuestions()
  }

  const generateQuestions = async () => {
    try {
      const response = await fetch('https://aceinterview.co.in/api/generate-questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobTitle: jobTitle,
          jobType: jobType,
          yearsOfExperience: yearsOfExperience,
          techSkills: techSkills.map(skill => skill.label).join(', '),
          behavioralSkills: behavioralSkills.map(skill => skill.label).join(', ')
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setInterviewStarted(true);
      let filteredQuestions = data.questions.filter(question => question.trim() !== "");
      setQuestions(filteredQuestions);
      setDisableButton(true);
      readOut(filteredQuestions[curQuestion], () => {
        startSpeechToText();
        setDisableButton(false);
      });
    } catch (error) {
      console.error('Error generating questions:', error);
    }
  };



  return (
    <div className='w-full'>
      <Header />
      <div className='flex flex-col items-center my-5'>
        {hasPermission ? (
          <div style={styles.webcamContainer}>
            <Webcam
              audio={false}
              height={420}
              width={680}
              videoConstraints={videoConstraints}
              onUserMedia={handleUserMedia}
              onUserMediaError={handleUserMediaError}
              className='rounded-lg shadow-md'
            />
            {disableButton && (
              <div style={styles.speakingContainer}>
                <div style={styles.imageContainer}>
                  <img className='rounded-full'
                    src="https://as2.ftcdn.net/v2/jpg/04/46/38/69/1000_F_446386956_DiOrdcxDFWKWFuzVUCugstxz0zOGMHnA.jpg"
                    alt="Speaking"
                    style={styles.speakingImage}
                  />
                  <div style={styles.waveContainer}>
                    <div style={{ ...styles.wave, ...styles.wave1 }}></div>
                    <div style={{ ...styles.wave, ...styles.wave2 }}></div>
                    <div style={{ ...styles.wave, ...styles.wave3 }}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col items-center'>
            <img
              src='https://via.placeholder.com/680x420.png?text=No+Camera+Access'
              alt='No camera access'
              className='rounded-lg shadow-md'
            />
            <p className='mt-5 text-gray-500'>Please enable camera and audio access to start the interview.</p>
          </div>
        )}

        {!interviewStarted && <button
          onClick={handleBeginInterview}
          className='mt-5 bg-teal-500 text-white font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-teal-600 transition duration-300'
        >
          Begin Interview
        </button>}


      </div>

      {hasPermission && questions && interviewStarted &&
        <div>
          <div className='my-4 text-center'>
            <h2 className='text-xl font-semibold'>{questions[curQuestion]}</h2>
            {/* <div className='text-center'>
              <ReactMic className='hidden'
                record={record}
                onStop={onStop}
                onData={onData}
                visualSetting="frequencyBars"
              />
              <br />
              <audio className='mx-auto my-2' controls id="myAudio" src={myAudioSrc}></audio>
            </div> */}
          </div>
          <div className='text-center mx-3'>
            {/* <div className='mr-4'>
              <h1>Recording: {isRecording.toString()}</h1>
              <button onClick={() => {
                if (isRecording) {
                  stopSpeechToText();
                  stopRecording();
                } else {
                  startSpeechToText();
                  startRecording();
                }
              }}>
                {isRecording ? 'Stop Recording' : 'Start Recording'}
              </button>
            </div> */}

            {/* <ul>
              {results.map((result) => (
                <li key={result.timestamp}>{result.transcript}</li>
              ))}
              {interimResult && <li>{interimResult}</li>}
            </ul> */}

            <button hidden={disableButton}
              onClick={handleNextClick}
              className='mt-3 bg-teal-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-teal-600 transition duration-300'
            > {curQuestion === questions.length - 1 ? 'Finish' : 'Next'}
            </button>
          </div>

        </div>
      }


    </div>
  );
};

export default Interview;
