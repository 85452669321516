import React from 'react';

const Header = () => {
    return (
        <header className="bg-slate-100 text-black py-4 px-2 shadow-md ml-2">
            <div className="container mx-auto flex justify-between items-center px-4">
                <h1 className="text-2xl font-bold">AceInterview</h1>
                <nav>
                    <ul className="flex space-x-5">
                        <li>
                            <a href="/pre-interview" className="hover:text-blue-200">PreInterview</a>
                        </li>
                        <li>
                            <a href="/interview" className="hover:text-blue-200">Interview</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
